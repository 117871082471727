import * as React from 'react';
import styled from '@emotion/styled';
import convert from 'htmr';
import Link from 'next/link';

import { themeProps, Heading, Paragraph, Box, Text } from 'components/design-system';
import { Column, Content } from 'components/layout';
import formatTime from 'utils/formatTime';
import { WordPressUser, WordPressCategory } from 'types/wp';
import { PostAuthor } from './components';

const Section = Content.withComponent('header');

const TextLink = Text.withComponent('a');

const CategoryLink = styled(TextLink)`
  text-decoration: none;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Root = styled(Section)`
  padding: ${themeProps.space.xl}px ${themeProps.space.md}px ${themeProps.space.md}px;

  ${themeProps.mediaQueries.md} {
    padding: ${themeProps.space.xxl}px ${themeProps.space.lg}px ${themeProps.space.lg}px;
  }
`;

interface PostHeaderProps {
  title: string;
  type?: string;
  description?: string;
  author?: WordPressUser;
  category?: WordPressCategory;
  date?: string;
}

const PostHeader: React.FC<PostHeaderProps> = ({
  title,
  description,
  type,
  author,
  category,
  date,
}) => {
  return (
    <Root noPadding noFlex>
      <Column>
        {category && (
          <Box mb="xs">
            <Link href="/category/[slug]" as={`/category/${category.slug}`} passHref>
              <CategoryLink variant={400}>{category.name}</CategoryLink>
            </Link>
          </Box>
        )}
        <Box>
          <Heading as="h1" variant={900}>
            {convert(title)}
          </Heading>
          {type === 'post' &&
            (category?.slug === 'artikel' || category?.slug === 'bacaan') && // hardcoded by slug
            description &&
            convert(description, {
              transform: {
                p: props => <Paragraph variant={500} mt="md" {...props} />,
              },
            })}
        </Box>
        {type === 'post' && author && (
          <Box
            display="flex"
            flexDirection={['column-reverse', null, null, null, 'row']}
            justifyContent={[null, null, null, null, 'space-between']}
            mt="md"
            pt="md"
            borderTopWidth="1px"
            borderTopStyle="solid"
            borderTopColor="accents03"
          >
            <PostAuthor author={author} />
            {date && (
              <Box mb={['md', null, null, null, 0]}>
                <Text>
                  <time dateTime={date}>{formatTime(new Date(date))}</time>
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Column>
    </Root>
  );
};

PostHeader.defaultProps = {
  type: 'page',
};

export default PostHeader;
