import * as React from 'react';
import styled from '@emotion/styled';
import { themeProps, Heading, Paragraph } from 'components/design-system';
import { Column, Content } from 'components/layout';

const Section = Content.withComponent('section');

const Root = styled(Section)`
  padding: ${themeProps.space.xl}px ${themeProps.space.md}px ${themeProps.space.md}px;

  ${themeProps.mediaQueries.sm} {
    padding: 6vh ${themeProps.space.md}px 4vh;
  }

  ${themeProps.mediaQueries.md} {
    padding: 6vh ${themeProps.space.lg}px 4vh;
  }
`;

interface SectionHeaderProps {
  title: string;
  description?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, description }) => {
  return (
    <Root noPadding noFlex>
      <Column>
        <Heading as="h1" variant={900} maxWidth={640}>
          {title}
        </Heading>
        {description && (
          <Paragraph variant={500} mt="md" maxWidth={640}>
            {description}
          </Paragraph>
        )}
      </Column>
    </Root>
  );
};

export default SectionHeader;
