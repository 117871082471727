import * as React from 'react';
import { Box, Text, Avatar } from 'components/design-system';
import { WordPressUser } from 'types/wp';
import styled from '@emotion/styled';
import Link from 'next/link';
import { logEventClick } from 'utils/analytics';

interface PostIndexAuthorProps {
  author: WordPressUser;
}

const AuthorLink = styled(Text)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const PostIndexAuthor: React.FC<PostIndexAuthorProps> = ({ author }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Avatar
        alt={`${author.name}'s Avatar`}
        src={author.avatar_urls['24'].replace(/^http:\/\//i, 'https://')}
        size={24}
      />
      <Link href="/author/[slug]" as={`/author/${author.slug}`} passHref>
        <AuthorLink as="a" variant={300} marginLeft="sm" onClick={() => logEventClick(author.name)}>
          {author.name}
        </AuthorLink>
      </Link>
    </Box>
  );
};

export default PostIndexAuthor;
