import * as React from 'react';
import { HtmrOptions } from 'htmr/src/types';
import {
  Heading,
  Paragraph,
  UnorderedList,
  OrderedList,
  ListItem,
  Blockquote,
  themeProps,
  Text,
  Box,
  Verse,
  VerseProps,
} from 'components/design-system';
import styled from '@emotion/styled';

const ContentHeading = styled(Heading)`
  margin-top: ${themeProps.space.xl}px;
  margin-bottom: ${themeProps.space.md}px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentParagraph = styled(Paragraph)`
  margin-bottom: ${themeProps.space.md}px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const VerseStyled = styled(Verse)`
  padding: 2em;
`;

const Table = styled(Box)`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled(Box)`
  > tr > th {
    text-align: inherit;
    font-weight: 700;
  }
`;

const TableCell = styled(Box)``;

const htmrTransform: HtmrOptions['transform'] = {
  h1: props => <ContentHeading variant={900} as="h1" {...props} />,
  h2: props => <ContentHeading variant={800} as="h2" {...props} />,
  h3: props => <ContentHeading variant={600} as="h3" {...props} />,
  h4: props => <ContentHeading variant={500} as="h4" {...props} />,
  h5: props => <ContentHeading variant={400} as="h5" {...props} />,
  h6: props => <ContentHeading variant={400} as="h6" {...props} />,
  p: props => <ContentParagraph mb="lg" {...props} />,
  pre: (props: VerseProps) => <VerseStyled variant={500} {...props} />, // Wordpress Verse
  figure: props => <Box as="figure" my="xxl" {...props} />,
  figcaption: props => (
    <Text as="figcaption" mt="sm" variant={300} color="accents07" textAlign="center" {...props} />
  ),
  table: props => (
    <Table as="table" mb="md" border="1px solid" borderColor="accents02" {...props} />
  ),
  thead: props => <TableHead as="thead" {...props} />,
  td: props => (
    <TableCell as="td" py="xs" px="sm" border="1px solid" borderColor="accents02" {...props} />
  ),
  th: props => (
    <TableCell as="th" py="xs" px="sm" border="1px solid" borderColor="accents02" {...props} />
  ),
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  blockquote: Blockquote,
};

export default htmrTransform;
