import * as React from 'react';
import styled from '@emotion/styled';
import { themeProps, Heading, Paragraph, Box } from 'components/design-system';
import { Content, Column } from 'components/layout';

const Section = Content.withComponent('section');

const Root = styled(Section)`
  padding: ${themeProps.space.xl}px ${themeProps.space.md}px ${themeProps.space.md}px;

  ${themeProps.mediaQueries.sm} {
    padding: 6vh ${themeProps.space.md}px 4vh;
  }

  ${themeProps.mediaQueries.md} {
    padding: 6vh ${themeProps.space.lg}px 4vh;
  }
`;

interface SectionHeaderProps {
  title: string;
  description?: string;
  avatar?: string;
}

const AvatarContainer = styled('div')`
  display: inline-flex;
  width: 96px;
  height: 96px;
  border: 2px solid ${themeProps.colors.foreground};
  border-radius: 96px;
  margin-bottom: 24px;
  overflow: hidden;
`;

const AvatarImage = styled('img')`
  width: 96px;
  height: 96px;
  object-fit: cover;
`;

const AuthorHeader: React.FC<SectionHeaderProps> = ({ title, description, avatar }) => {
  return (
    <Root noPadding noFlex>
      <Column>
        <Box display="flex" flex-direction="column" align-items="center" justifyContent="center">
          <Box style={{ textAlign: 'center' }}>
            {avatar && (
              <AvatarContainer>
                <AvatarImage
                  src={avatar.replace(/^http:\/\//i, 'https://')}
                  alt={`${title}'s Avatar`}
                />
              </AvatarContainer>
            )}
            <Heading variant={800} maxWidth={640}>
              {title}
            </Heading>
            {description && (
              <Paragraph mt="xs" variant={500} maxWidth={640}>
                {description}
              </Paragraph>
            )}
          </Box>
        </Box>
      </Column>
    </Root>
  );
};

export default AuthorHeader;
