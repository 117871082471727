import * as React from 'react';
import Link from 'next/link';
import convert from 'htmr';
import styled from '@emotion/styled';

import { Box, Stack, Heading, Text, UnstyledAnchor } from 'components/design-system';
import { WordPressPostIndex, WordPressUser } from 'types/wp';
import { DekontaminasiHoaxPosts } from 'types/dekontaminasi';
import formatTime from 'utils/formatTime';
import { logEventClick } from 'utils/analytics';
import htmrTransform from './utils/htmrTransform';

import { PostIndexAuthor } from './components';

type PostProps = WordPressPostIndex | DekontaminasiHoaxPosts;

interface PostIndexCardProps {
  post: PostProps;
  author?: WordPressUser;
  hasExcerpt?: boolean;
}

const PostLink = styled(UnstyledAnchor)`
  box-shadow: unset;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    box-shadow: unset;
  }

  &:hover,
  &:focus {
    h3 {
      text-decoration: underline;
    }
  }
`;

const PostIndexCard: React.FC<PostIndexCardProps> = ({ post, author, hasExcerpt }) => {
  return (
    <Box as="article" display="flex" flexDirection="column" backgroundColor="card" borderRadius={8}>
      <Link
        href={`${
          (post as WordPressPostIndex).slug
            ? '/content/[...paths]'
            : (post as DekontaminasiHoaxPosts).url
        }`}
        as={`${
          (post as WordPressPostIndex).slug
            ? `/content/${(post as WordPressPostIndex).id}/${(post as WordPressPostIndex).slug}`
            : (post as DekontaminasiHoaxPosts).url
        }`}
        passHref
      >
        <PostLink
          flex="1 1 auto"
          onClick={() => {
            logEventClick(
              (post as WordPressPostIndex).slug
                ? (post as WordPressPostIndex).title.rendered
                : (post as DekontaminasiHoaxPosts).title
            );
          }}
          aria-label={
            (post as WordPressPostIndex).slug
              ? (post as WordPressPostIndex).title.rendered
              : (post as DekontaminasiHoaxPosts).title
          }
        >
          <Stack spacing="md" p="md">
            <Box as="header">
              <Heading variant={600} as="h3">
                {(post as WordPressPostIndex).slug
                  ? convert((post as WordPressPostIndex).title.rendered)
                  : convert((post as DekontaminasiHoaxPosts).title)}
              </Heading>
            </Box>
            {hasExcerpt ? (
              <Box as="section">
                {convert((post as WordPressPostIndex).excerpt.rendered, {
                  transform: htmrTransform,
                })}
              </Box>
            ) : null}
          </Stack>
        </PostLink>
      </Link>
      <Box as="footer" display="flex" flexDirection="row" alignItems="center" p="md" pt={0}>
        {author && <PostIndexAuthor author={author} />}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          flex="1 1 auto"
          height={24}
        >
          <Text variant={300} fontFamily="monospace">
            <time
              dateTime={
                (post as WordPressPostIndex).slug
                  ? (post as WordPressPostIndex).date_gmt
                  : new Date((post as DekontaminasiHoaxPosts).timestamp).toString()
              }
            >
              {formatTime(
                new Date(
                  (post as WordPressPostIndex).slug
                    ? (post as WordPressPostIndex).date_gmt
                    : (post as DekontaminasiHoaxPosts).timestamp
                )
              )}
            </time>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

PostIndexCard.defaultProps = {
  hasExcerpt: false,
};

export default PostIndexCard;
