import * as React from 'react';
import { Box, Text, Avatar } from 'components/design-system';
import { WordPressUser } from 'types/wp';
import styled from '@emotion/styled';
import Link from 'next/link';
import { logEventClick } from 'utils/analytics';

interface PostAuthorProps {
  author: WordPressUser;
}

const AuthorLink = styled(Text)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const PostAuthor: React.FC<PostAuthorProps> = ({ author }) => {
  const { name, avatar_urls, slug, description } = author;
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Avatar
        alt={`${name}'s Avatar`}
        src={avatar_urls['48'].replace(/^http:\/\//i, 'https://')}
        size={48}
      />
      <Box display="flex" flexDirection="column" flex="1 1 auto" marginLeft="sm">
        <Link href="/author/[slug]" as={`/author/${slug}`} passHref>
          <AuthorLink
            as="a"
            fontWeight="600"
            mb="xxs"
            onClick={() => logEventClick(`/author/${slug}`)}
          >
            {name}
          </AuthorLink>
        </Link>
        {description && <Text variant={300}>{description}</Text>}
      </Box>
    </Box>
  );
};

export default PostAuthor;
